<template>
  <div class="flex items-center justify-center inset-0 ...">
    <div class="w-full bg-img fontMedium" >
      <div class="mx-auto self-center" :style="[ windowWidth<=500? {'width':'98%'}: {'width':'70%'}]">
        <div
          class="absolute justify-center mt-5"
          style="max-width: 100px; margin-left: 31%; margin-right: 31%"
          slot="no-body"
        >
        <popLogo />
        </div>
        <div slot="no-body" class="full-page-bg-color CustemBG">
          <div class="vx-row">
            <div
              class="
                vx-col
                hidden
                sm:hidden
                md:hidden
                lg:block lg:w-1/2
                mx-auto
              "
            >
              <p
                class="pl-10 pr-10"
                style="
                  text-align: center;
                  font: normal normal 50 25px/41px UbuntuRegular;
                  color: #ffffff;
                  opacity: 1;
                "
              >
                {{ $t("ProccedOnlineAppointment") }}
                <br />
                {{ $t("withDoctor") + " " + sessionModel.DoctorName }}
              </p>
              <div class="pl-10 pr-10">
                <div class="vx-row pt-3 mb-2">
                  <div class="vx-col pt-2">
                    <img
                      src="@/assets/images/logo/appointment.png"
                      style="max-width: 50px"
                      alt=""
                    />
                  </div>
                  <div class="vx-col">
                    <p class="CurrentPageStatment pt-3">
                      {{ $t("STEPONE") }}<br />{{ $t("OneSetAppointment") }}
                    </p>
                  </div>
                </div>

                <hr class="line" />

                <div class="vx-row pt-3 mb-5">
                  <div class="vx-col pt-2">
                    <img
                      src="@/assets/images/logo/document.png"
                      style="max-width: 40px; opacity: 0.3"
                      alt=""
                    />
                  </div>
                  <div class="vx-col">
                    <p class="OtherPageStatment pt-4">
                      {{ $t("STEPTWO") }}<br />{{ $t("TwoSetAppointment") }}
                    </p>
                  </div>
                </div>
                <hr class="line" style="opacity: 0.3" />

                <div class="vx-row pt-3">
                  <div class="vx-col pt-2">
                    <img
                      src="@/assets/images/logo/payment-method.png"
                      style="max-width: 50px; opacity: 0.3"
                      alt=""
                    />
                  </div>

                  <div class="vx-col">
                    <p class="OtherPageStatment pt-4">
                      {{ $t("STEPTREE") }}<br />{{ $t("ThreeSetAppointment") }}
<br />
                    </p>
                  </div>
                </div>

              </div>
            </div>
            <div
              class="
                vx-col
                sm:w-full
                md:w-full
                lg:w-1/2
                mx-auto
                d-theme-dark-bg
                lg:block
              "
              style="border-radius: 31px 31px 31px 31px"
            >
              <div class="p-5 mt-8">
                <div class="vx-card__title"></div>

                <div class="vx-col sm:w-full md:w-full mx-auto self-center">
                  <p style="color: orange">
                    {{ $t("AppointmentPatientConditionTitle") }}:
                  </p>

                  <ul class="ml-8 mb-3">
                    <li style="color: orange">
                      {{ $t("AppointmentPatientConditionFirst") }}
                    </li>
                    <li style="color: orange">
                      {{ $t("AppointmentPatientConditionSecond") }}
                    </li>
                    <li style="color: orange">
                      {{ $t("AppointmentPatientConditionThird") }}
                    </li>
                  </ul>

                  <div class="vx-row ml-5 w-full">
                    <div class="vx-col lg:w-2/5 sm:w-full" >

                      <imageLazy
                      :imageStyle="{ 'border-radius': '20px' }"
                      :imageClass="'doctorImage'"
                      :src="baseURL + sessionModel.doctorImg"
                      placeHolderType="doctor"
                    />
                    </div>
                    <div class="vx-col lg:w-3/5 sm:w-full">
                      <div class="vx-row text-start mt-1 ">
                        <label class="col lg:w-1/3" for="fname"
                          >{{ $t("Date") }} :</label
                        >
                        <p class="text-start">
                          {{ changeFormatedDate(sessionModel.SessionDate) }}
                        </p>
                      </div>
                      <div class="vx-row text-start mt-1">
                        <label class="col lg:w-1/3 " for="fname"
                          >{{ $t("Time") }} :</label
                        >
                        <p class="text-start"
                          v-if="
                            sessionModel.TimeFrom != null &&
                            sessionModel.TimeTo != null
                          "
                        >
                          {{ sessionModel.TimeFrom.slice(0, 5) }} -
                          {{ sessionModel.TimeTo.slice(0, 5) }}
                        </p>
                      </div>
                      <div class="vx-row text-start mt-1">
                        <label class="col lg:w-1/3 " for="fname"
                          >{{ $t("Doctor") }} :</label
                        >
                        <p>{{ sessionModel.DoctorName }}</p>
                      </div>
                      <div class="vx-row text-start mt-1">
                        <label class="col lg:w-1/3 " for="fname"
                          >{{ $t("Destination") }} :</label
                        >
                        <p>
                          {{ sessionModel.Country }}
                        </p>
                      </div>
                      <div class="vx-row text-start mt-1">
                        <label class="col lg:w-1/3 " for="fname"
                          >{{ $t("Speciality") }} :</label
                        >
                        <p>{{ sessionModel.Specialties }}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="
                    flex flex-wrap
                    justify-center
                    flex-row-reverse
                    sm:flex-row
                  "
                >
                  <vs-button
                    class="w-full sm:w-auto text-center mt-3"
                    color="rgb(62, 201, 214)"
                    @click="Proceed()"
                    >{{ $t("Book") }}</vs-button
                  >
                </div>
                <div class="text-center mt-2">
                  <vs-button
                    type="line"
                    color="black"
                    @click="closeSetSession()"
                    class="w-full sm:w-auto mb-8 sm:mb-auto mt-3 sm:mt-auto"
                    >{{ $t("BackAndChangeYourSelection") }}</vs-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { domain } from "@/gloabelConstant.js";
import imageLazy from "@/components/image_lazy.vue";
export default {
components:{
    imageLazy
  },


  data() {

    return {
      updatedPatientSession: {},
      isSelected: false,
      baseURL: domain,
      appointmentDate: new Date(),
      appointmentDateStrig: "",
      sessions: [],
    };
  },
  props: {
    sessionModel: {
      type: Object,
      default: () => {},
    },
  },
computed:{
   windowWidth() {
      return this.$store.state.windowWidth;
    },
},
  methods: {
    changeFormatedDate(date) {
      return moment(date).format("LL");
    },

    closeSetSession() {
      debugger;
      this.showCancelPop = true;
      this.sessionModel = {};
      this.$emit("closePop");
    },

    Proceed() {
      debugger;
      var model = {};
      model.PatientID = this.$store.state.AppActiveUser.Patient.ID;
      if (this.sessionModel.SessionID)
        model.DoctorSessionID = this.sessionModel.SessionID;
      else model.DoctorSessionID = this.sessionModel.ID;

      model.DoctorID = this.sessionModel.DoctorID;
      model.PaymentStatusID = 1; //pending
      model.StatusID = 1;
      model.MarkupFees=this.sessionModel.MarkupFees;
       model.VAT=this.sessionModel.VAT;
      this.$vs.loading();
      var vm = this;
      this.$store
        .dispatch("DoctorList/AddPatientReservationSession", model)
        .then((res) => {
          if (res.status == 200) {
            debugger;
            this.$vs.loading.close();
            vm.updatedPatientSession = res.data.Data;
            this.showSetSession = false;
            //this.showSetPayment = true;
            window.showAddSuccess();

            this.isSelected = false;
            this.$emit("closePop", vm.updatedPatientSession);
          }
        })
        .catch((err) => {
          console.log(err)
          window.showError(err.response.data.Message);

           //this.$emit("closePop", {"ID":0});
          this.$vs.loading.close();
        });
    },

    addHours(date, h) {
      if (typeof date === "string") date = new Date(date);
      date.setTime(date.getTime() + h * 60 * 60 * 1000);
      return date;
    },
  },
  mounted() {},
};
</script>
<style scoped>
.doctorImage {
  width: 100px;
  height: 100px;
  text-align: end;
}
/* */
.CurrentPageStatment {
  top: 414px;
  left: 600px;
  height: 100%;
  width: 100%;
  color: var(--unnamed-color-ffffff);
  text-align: left;
  letter-spacing: NaNpx;
  color: #ffffff;
  opacity: 1;
  font-family: UbuntuRegular;
  font-size: 1.3rem;
}
/*  */
.OtherPageStatment {
  top: 532px;
  left: 600px;
  height: 100%;
  width: 100%;
  color: var(--unnamed-color-ffffff);
  text-align: left;
  letter-spacing: NaNpx;
  color: #ffffff;
  opacity: 0.3;
  font-family: UbuntuRegular;
  font-size: 1.3rem;
}
.text-1xl {
  color: #393939;
  font-size: 1rem;
  letter-spacing: 0px;
  opacity: 0.5;
}
.CustemBG {
  background: transparent radial-gradient(closest-side at 50% 50%, #2f5f80 0%, #003267 100%) 0% 0% no-repeat padding-box;
  box-shadow: -3px 0px 22px #00000029;
  border-radius: 31px 31px 31px 31px;
  opacity: 1;
}
li {
  margin: 0;
  position: relative;
}

</style>
