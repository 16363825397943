<template>
  <div class="flex items-center justify-center inset-0 ...">
    <div class="flex w-full bg-img fontMedium">
      <div
        class="mx-auto self-center"
        :style="[windowWidth <= 500 ? { width: '96%' } : { width: '70%' }]"
      >
        <!-- img side -->
        <div
          class="absolute justify-center mt-5"
          style="max-width: 130px; margin-left: 31%; margin-right: 31%"
          slot="no-body"
        >
          <popLogo />
        </div>
        <div slot="no-body" class="full-page-bg-color CustBGPay">
          <div class="vx-row">
            <!-- RIGHT SIDE SECTION -->
            <div
              class="
                vx-col
                hidden
                sm:hidden
                md:hidden
                lg:block lg:w-1/2
                mx-auto
              "
            >
              <p
                class="pl-10 pr-10"
                style="
                  text-align: center;
                  font: normal normal 50 25px/41px UbuntuRegular;
                  letter-spacing: 0px;
                  color: #ffffff;
                  opacity: 1;
                "
              >
                {{ $t("ProccedOnlineAppointment") }} <br />
                {{ $t("withDoctor") + " " + sessionModel.DoctorName }}
              </p>
              <div class="pl-10 pr-10">
                <!-- STEP ONE SECTION -->
                <div class="vx-row">
                  <div class="vx-col pt-2">
                    <img
                      src="@/assets/images/logo/appointment.png"
                      style="max-width: 50px; opacity: 0.3"
                      alt=""
                    />
                  </div>
                  <div class="vx-col pt-3">
                    <p class="OtherPageStatment">
                      {{ $t("STEPONE") }}<br />{{ $t("OneSetAppointment") }}
                    </p>
                  </div>
                </div>
                <hr class="line" style="opacity: 0.3" />
                <!-- STEP TWO SECTION -->
                <div class="vx-row">
                  <div class="vx-col pt-3">
                    <img
                      src="@/assets/images/logo/document.png"
                      style="max-width: 40px"
                      alt=""
                    />
                  </div>
                  <div class="vx-col pt-3">
                    <p class="CurrentPageStatment">
                      {{ $t("STEPTWO") }}<br />{{ $t("TwoSetAppointment") }}
                    </p>
                  </div>
                </div>
                <hr class="line" />
                <!-- STEP THREE SECTION -->
                <div class="vx-row">
                  <div class="vx-col pt-3">
                    <img
                      src="@/assets/images/logo/payment-method.png"
                      style="max-width: 50px; opacity: 0.3"
                      alt=""
                    />
                  </div>

                  <div class="vx-col pt-3">
                    <p class="OtherPageStatment">
                      {{ $t("STEPTREE") }}<br />{{ $t("ThreeSetAppointment") }}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <!-- LEFT SIDE SECTION -->
            <div
              class="
                vx-col
                sm:w-full
                md:w-full
                lg:w-1/2
                mx-auto
                d-theme-dark-bg
                lg:block
              "
              style="border-radius: 31px 31px 31px 31px"
            >
              <div class="p-5" style="margin-top: 30px">
                <div class="vx-row text-center">
                  <p
                    class="
                      vx-col
                      sm:w-full
                      md:w-full
                      mx-auto
                      self-center
                      d-theme-dark-bg
                      col
                      pl-10
                      pr-10
                    "
                    style="
                      text-align: center;
                      font: normal normal 50 18px/25px UbuntuRegular;
                      letter-spacing: 0px;
                      opacity: 1;
                    "
                  >
                    {{ $t("AppointmentPatientNote") }}
                  </p>
                </div>
                <div class="vx-card__title mb-2"></div>

                <!-- start -->

                <div
                  class="
                    vx-row
                    sm:w-full
                    md:w-full
                    mx-auto
                    self-center
                    d-theme-dark-bg
                  "
                >
                  <div class="mt-2 w-full">
                    <p style="color: black">
                      {{ $t("AppointmentPatientNotePlease") }}
                    </p>

                    <vs-textarea
                      style="color: black"
                      class="text-1xl w-full"
                      :placeholder="$t('PleaseTypeHere')"
                      v-model="patientNote"
                    >
                    </vs-textarea>
                  </div>
                   <span>{{$t("selectAlreadyAddDoc")}}</span>
                  <div style="width: 70%">
                    <v-select
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      class="mb-5"
                      label="Description"
                      v-model="documentUploadedModel"
                      :options="ProfileDocument"
                      :reduce="(ID) => ID"
                      :placeholder="$t('UploadedDocument')"
                    />
                  </div>

                  <div style="width: 15%">
                    <vs-button
                      type="line"
                      color="black"
                      @click="AddFromProfileReport()"
                      class="
                        w-full
                        m-12
                        sm:w-auto
                        mb-8
                        sm:mb-auto
                        mt-3
                        sm:mt-auto
                      "
                      >{{ $t("Add") }}
                    </vs-button>
                  </div>

                  <div class="md:w-full sm:w-full text-center w-full">
                    <v-select
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      class="mb-5"
                      label="Name"
                      v-model="documentModel.DocumentID"
                      :options="DocumentTypes"
                      :reduce="(ID) => ID.ID"
                      :placeholder="$t('DocumentType')"
                    />
                  </div>

                  <div
                    class="md:w-1/2 sm:w-full w-full text-center mb-1"
                    v-if="documentModel.DocumentID == 1"
                  >
                    <v-select
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      label="Name"
                      v-model="documentModel.DocumentTypeID"
                      :options="ScanTypes"
                      :reduce="(ID) => ID.ID"
                      :placeholder="$t('ScanType')"
                    />
                  </div>
                  <div
                    class="md:w-1/2 sm:w-full w-full text-center mb-1"
                    v-if="documentModel.DocumentID == 2"
                  >
                    <v-select
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      label="Name"
                      v-model="documentModel.DocumentTypeID"
                      :options="AnalysisTypes"
                      :reduce="(ID) => ID.ID"
                      :placeholder="$t('AnalysisType')"
                    />
                  </div>
                  <div
                    class="md:w-full sm:w-full w-full text-center mb-1"
                    v-if="documentModel.DocumentID == 3"
                  >
                    <v-select
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      label="Name"
                      v-model="documentModel.DocumentTypeID"
                      :options="ReportTypes"
                      :reduce="(ID) => ID.ID"
                      :placeholder="$t('ReportType')"
                    />
                  </div>
                  <div class="md:w-full sm:w-full w-full text-center mb-1">
                    <vs-input
                      class="w-full"
                      v-model="documentModel.Description"
                      :placeholder="$t('Description')"
                    />
                  </div>
                  <div
                    class="md:w-full sm:w-full w-full text-center"
                    v-if="documentModel.DocumentID > 0"
                  >
                    <div>
                      <input
                        :disabled="
                          !(
                            documentModel.DocumentID > 0 &&
                            documentModel.DocumentTypeID > 0 &&
                            documentModel.Description
                          )
                        "
                        type="file"
                        class=""
                        accept=".pdf"
                        @change="AttachmentUploaded"
                        @on-success="successUpload"
                      />
                    </div>
                  </div>
                  <div
                    class="vx-col md:w-1/2 sm:w-1/2 text-center d-theme-dark-bg"
                    v-if="documentModel.DocumentID > 0"
                  >
                    <!-- <div class="text-center">
                      <vs-button
                        class="ml-30 mb-3"
                        color="primary"
                        style="float: right"
                        @click="AddAttachmnet"
                        >{{ $t("Add") }}</vs-button
                      >
                    </div> -->
                  </div>
                </div>

                <div class="vx-row sm:w-full md:w-full PatrientNote">
                  <table
                    style="width: 100%; background-color: white"
                    class="mt-5"
                  >
                    <tr
                      class="HeaderTableColor fontheader"
                      style="height: 50px"
                    >
                      <th class="text-center">
                        {{ $t("Description") }}
                      </th>

                      <th class="text-center">
                        {{ $t("DocumentType") }}
                      </th>
                      <th class="text-center"></th>
                    </tr>
                    <tr
                      :data="tr"
                      :key="indextr"
                      v-for="(tr, indextr) in ReportDocuments"
                      style="height: 20px"
                    >
                      <td class="text-center">
                        <p class="product-name font-medium truncate">
                          {{ tr.Description }}
                        </p>
                      </td>
                      <td class="text-center">
                        <p class="product-name font-medium truncate">
                          {{ tr.Name }}
                        </p>
                      </td>

                      <td style="width: 10px">
                        <div class="">
                          <feather-icon
                            icon="TrashIcon"
                            @click.stop="RemoveDocuments(indextr)"
                            class="cursor-pointer mt-2"
                          ></feather-icon>

                        </div>
                      </td>
                    </tr>

                    <!-- <tr style="height: 50px"></tr> -->
                  </table>
                </div>



                <div class="flex-wrap flex-col-reverse sm:flex-row mb-2">
                  <div class="text-center mb-2">
                    <vs-button
                      class="w-full sm:w-auto text-center text-primary mb-2"
                      color="black"
                      type="line"
                      style="float: right"
                      @click="SaveModel"
                      >{{ $t("OK") }}</vs-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import axios from "@/axios.js";
import { domain, mainSetting } from "@/gloabelConstant.js";
import modulePatient from "@/store/Patient/modulePatient.js";
import moduleDocumentType from "@/store/settings/documentType/moduleDocumentType.js";
import moduleAnalysisType from "@/store/settings/analysisType/moduleAnalysisType.js";
import moduleReportType from "@/store/settings/reportType/moduleReportType.js";
import moduleScanType from "@/store/settings/scanType/moduleScanType.js";

export default {
  data() {
    return {
      isSelected: false,
      appointmentDate: new Date(),
      appointmentDateStrig: "",
      sessions: [],
      ReportDocuments: [],
      ProfileReportDocuments: [],
      ProfileReportDocumentID: "",
      documentUploadedModel: null,
      uploaded: false,
      patientNote: "",
      UploadRequestHeader: mainSetting.UploadRequestHeader,
      uploadUrl: domain + "api/Common/UploadFile",
      baseURL: domain,
    };
  },
  props: {
    updatedPatientSession: {
      type: Object,
      default: () => {},
    },
    sessionModel: {
      type: Object,
      default: () => {},
    },
    documentModel: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    windowWidth() {
      return this.$store.state.windowWidth;
    },
    DocumentTypes() {
      debugger;
      return this.$store.state.DocumentTypeList.DocumentTypes;
    },
    ProfileDocument() {
      debugger;
      return this.ProfileReportDocuments;
    },
    ScanTypes() {
      debugger;
      return this.$store.state.ScanTypeList.ScanTypes;
    },
    ReportTypes() {
      debugger;
      return this.$store.state.ReportTypeList.ReportTypes;
    },
    AnalysisTypes() {
      debugger;
      return this.$store.state.AnalysisTypeList.AnalysisTypes;
    },
  },
  methods: {
    SaveModel() {
      debugger;
      // this.ReportDocuments.forEach(element => {
      //   this.documentModel={};
      //   this.documentModel = element;
      //    this.AddAttachmnet();
      // });
      this.AddListOfAttachment();
    },
    AddListOfAttachment() {
      this.$store
        .dispatch(
          "patientList/AddListPatientMedicalDocument",
          this.ReportDocuments
        )
        .then(() => {
          this.SavePatientNote();
        });
    },
    // getFileName(AttachPath) {
    //   if (AttachPath != null) {
    //     var first = AttachPath.split("/")[1];
    //     debugger;
    //     return first;
    //   } else {
    //     return "";
    //   }
    // },
    // AddFromProfileReport() {
    //   var counter;
    //   var splitvar;
    //   this.documentModel = {};
    //   for (
    //     counter = 0;
    //     counter < this.ProfileReportDocuments.length;
    //     counter++
    //   ) {
    //     if (
    //       this.ProfileReportDocuments[counter].AttachPath ==
    //       this.ProfileReportDocumentID
    //     ) {
    //       debugger;
    //       if (this.ProfileReportDocuments[counter].DocumentID == 1)
    //         this.documentModel.Name = "Scan Type";
    //       if (this.ProfileReportDocuments[counter].DocumentID == 2)
    //         this.documentModel.Name = "Analysis Type";
    //       if (this.ProfileReportDocuments[counter].DocumentID == 3)
    //         this.documentModel.Name = "Report Type";
    //       debugger;
    //       splitvar =
    //         this.ProfileReportDocuments[counter].AttachPath.split("/")[2];

    //         debugger;
    //       this.documentModel.Attach = splitvar;
    //       this.documentModel.DocumentID =
    //         this.ProfileReportDocuments[counter].DocumentID;
    //       this.ReportDocuments.push(this.documentModel);
    //       debugger;

    //       this.documentModel.AttachPath = splitvar;
    //       debugger;
    //       this.AddAttachmnet();

    //       //this.ReportDocuments.push();
    //     }
    //   }
    //   debugger;
    // },
    AddFromProfileReport() {
      debugger;
      if (this.documentUploadedModel.DocumentID!=null && this.documentUploadedModel.DocumentID>0)
      {
        this.documentModel = {};
      this.documentModel.Description =
        this.documentUploadedModel.Description +
        "-" +
        "Session-" +
        this.updatedPatientSession.ID;
      if (this.documentUploadedModel.DocumentID == 1)
        this.documentModel.Name = "Scan Type";
      if (this.documentUploadedModel.DocumentID == 2)
        this.documentModel.Name = "Analysis Type";
      if (this.documentUploadedModel.DocumentID == 3)
        this.documentModel.Name = "Report Type";
      this.documentModel.DocumentID = this.documentUploadedModel.DocumentID;
      this.documentModel.DocumentTypeID =
        this.documentUploadedModel.DocumentTypeID;
      this.documentModel.ReportTypeID = this.documentUploadedModel.ReportTypeID;
      this.documentModel.ScanTypeID = this.documentUploadedModel.ScanTypeID;
      this.documentModel.AnalysisTypeID =
        this.documentUploadedModel.AnalysisTypeID;
      this.documentModel.AttachPath = this.documentUploadedModel.AttachPath;
      this.documentModel.PatientID = this.$store.state.AppActiveUser.Patient.ID;
      this.ShowPatientUploadDocument = true;
      this.documentModel.PatientReservationSessionID =
        this.updatedPatientSession.ID;
      debugger;
      this.documentModel.DoctorID = this.updatedPatientSession.DoctorID;
      this.documentModel.IsFromSession = true;
      this.ReportDocuments.push(this.documentModel);
      this.documentUploadedModel = {};
      this.documentModel = {};

      }

      debugger

    },

    SavePatientNote() {
      debugger;
      var model = {};
      model.ID = this.updatedPatientSession.ID;
      model.SymptomNote = this.patientNote;

      this.$vs.loading();
      this.$store
        .dispatch("DoctorList/AddPatientReservationSessionSymptomNote", model)
        .then((res) => {
          if (res.status == 200) {
            debugger;
            this.$vs.loading.close();
            this.updatedPatientSession = res.data.Data;
            this.ShowPatientNote = false;
            window.showAddSuccess();
            var ID = this.updatedPatientSession.ID;
            this.$emit("closePop", true);
            //this.$router.push("/Patient/Home");
            debugger;
            //, params: {Flag:True ,SessionID:this.updatedPatientSession.ID}
          }
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },
    RemoveDocuments(index) {
      this.ReportDocuments.splice(index, 1);
    },
    AddAttachmnet() {
      debugger;
      this.documentModel.PatientID = this.$store.state.AppActiveUser.Patient.ID;
      this.ShowPatientUploadDocument = true;
      this.documentModel.PatientReservationSessionID =
        this.updatedPatientSession.ID;
      debugger;
      this.documentModel.DoctorID = this.updatedPatientSession.DoctorID;
      this.documentModel.IsFromSession = true;
      debugger;
      this.$store
        .dispatch("patientList/AddPatientMedicalDocument", this.documentModel)
        .then((res) => {
          if (res.status == 200) {
            debugger;
            if (this.documentModel.IsFromSession != true) {
              // this.getModel();
              this.$emit("openMedicalDocument");
            }
            debugger;
            //document.getElementsByClassName("btn-x-file")[0].click();
            window.showAddSuccess();
            this.uploaded = false;
          }
        })
        .catch(() => {
          debugger;
          window.showError();
        });
      debugger;
      var isSession = this.documentModel.IsFromSession;
      var PatientReservationSessionID =
        this.documentModel.PatientReservationSessionID;
      var DoctorID = this.documentModel.DoctorID;
      this.documentModel = {};

      this.documentModel.PatientReservationSessionID =
        PatientReservationSessionID;
      this.documentModel.DoctorID = DoctorID;
      this.documentModel.IsFromSession = isSession;
    },
    successUpload(event) {
      debugger;

      window.showAddSuccess();
    },
    AttachmentUploaded(input) {

      debugger;
      if (input.target.files && input.target.files[0]) {
        debugger;
        let data = new FormData();
        data.append("file", input.target.files[0]);
        debugger;
        axios.post(this.uploadUrl, data,{
          headers: {
              "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
          debugger;
          var temp = {};
          if (response.data !="File not Supoorted")
          {
            temp.AttachPath = response.data;
          debugger;
          if (this.documentModel.DocumentID == 1)
            this.documentModel.Name = "Scan Type";
          if (this.documentModel.DocumentID == 2)
            this.documentModel.Name = "Analysis Type";
          if (this.documentModel.DocumentID == 3)
            this.documentModel.Name = "Report Type";
          debugger;
          this.documentModel.Attach = input.target.files[0].name;
          this.documentModel.AttachPath = response.data;

          this.documentModel.PatientID =
            this.$store.state.AppActiveUser.Patient.ID;
          this.ShowPatientUploadDocument = true;
          this.documentModel.PatientReservationSessionID =
            this.updatedPatientSession.ID;
          debugger;
          this.documentModel.DoctorID = this.updatedPatientSession.DoctorID;
          this.documentModel.IsFromSession = true;
          this.ReportDocuments.push(this.documentModel);
          this.documentModel = {};
          }
          else
          {
            this.ShowPatientUploadDocument = false;
          }


          // this.AddAttachmnet();
        });
      }

    },
  },
  created() {
    if (!moduleScanType.isRegistered) {
      this.$store.registerModule("ScanTypeList", moduleScanType);
      moduleScanType.isRegistered = true;
    }
    this.$store.dispatch("ScanTypeList/GetAllScanTypes");

    if (!moduleReportType.isRegistered) {
      this.$store.registerModule("ReportTypeList", moduleReportType);
      moduleReportType.isRegistered = true;
    }
    this.$store.dispatch("ReportTypeList/GetAllReportTypes");

    if (!moduleAnalysisType.isRegistered) {
      this.$store.registerModule("AnalysisTypeList", moduleAnalysisType);
      moduleAnalysisType.isRegistered = true;
    }
    this.$store.dispatch("AnalysisTypeList/GetAllAnalysisTypes");

    if (!moduleDocumentType.isRegistered) {
      this.$store.registerModule("DocumentTypeList", moduleDocumentType);
      moduleDocumentType.isRegistered = true;
    }
    this.$store.dispatch("DocumentTypeList/GetAllDocumentTypes");
    if (!modulePatient.isRegistered) {
      this.$store.registerModule("patientList", modulePatient);
      modulePatient.isRegistered = true;
    }

    this.$store
      .dispatch(
        "patientList/GetAllProfilePatientMedicalReports",
        this.$store.state.AppActiveUser.Patient.ID
      )
      .then((res) => {
        if (res.status == 200) {
          this.ProfileReportDocuments = res.data.Data;
        }
      })
      .catch(() => {
        window.showError();
      });
  },
  mounted() {},
};
</script>
<style>
#PatrientNote table {
  border-collapse: collapse;
  border-radius: 2em;
  overflow: hidden;
}
#PatrientNote th,
#PatrientNote td {
  border: 1px solid rgb(255, 255, 255);
  border-collapse: collapse;
}
[dir] #PatrientNote th,
[dir] #PatrientNote td {
  border: 1px solid #cfcfcf !important;
}
</style>
<style>
.doctorImage {
  width: 100px;
  height: 100px;
  text-align: end;
}
.CurrentPageStatment {
  top: 414px;
  left: 600px;
  width: 204px;
  height: 95px;
  color: var(--unnamed-color-ffffff);
  text-align: left;
  letter-spacing: NaNpx;
  color: #ffffff;
  font-family: UbuntuRegular;
  opacity: 1;
}
.OtherPageStatment {
  top: 532px;
  left: 600px;
  width: 215px;
  height: 99px;
  color: var(--unnamed-color-ffffff);
  text-align: left;
  letter-spacing: NaNpx;
  color: #ffffff;
  font-family: UbuntuRegular;
  opacity: 0.3;
}
.text-1xl {
  color: #393939;

  font-size: 1rem;
  letter-spacing: 0px;

  opacity: 0.5;
}
.CustBG {
  background: transparent
    radial-gradient(closest-side at 50% 50%, #2f5f80 0%, #003267 100%) 0% 0%
    no-repeat padding-box;
  box-shadow: -3px 0px 22px #00000029;
  border-radius: 31px 31px 31px 31px;
  opacity: 1;
}
</style>
