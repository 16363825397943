<template>
  <div id="UPMReport" class="items-center justify-center inset-0 ..." style="">
    <div
      class="p-8 lg:w-1/3 w-full"
      style="
        
        background-color: white;
        border-radius: 10px;
        margin: auto !important;
      "
    >
      <div class="vx-card__title mb-4"></div>
      <div
        class="vx-row sm:w-full md:w-full mx-auto self-center d-theme-dark-bg"
      >
        <div class="vx-col lg:w-1/2 md:w-/3 w-full text-center">
          <v-select
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
            class="mb-10"
            label="Name"
            v-model="documentModel.DocumentID"
            :options="DocumentTypes"
            :reduce="(ID) => ID.ID"
            :placeholder="$t('DocumentType')"
          />
        </div>
        <div
          class="vx-col md:w-1/2 lg:w-1/2 w-full mb-4 text-center"
          v-if="documentModel.DocumentID == 1"
        >
          <v-select
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
            label="Name"
            v-model="documentModel.DocumentTypeID"
            :options="ScanTypes"
            :reduce="(ID) => ID.ID"
            :placeholder="$t('ScanType')"
          />
        </div>
        <div
          class="vx-col md:w-1/2 lg:w-1/2  mb-4 w-full text-center"
          v-if="documentModel.DocumentID == 2"
        >
          <v-select
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
            label="Name"
            v-model="documentModel.DocumentTypeID"
            :options="AnalysisTypes"
            :reduce="(ID) => ID.ID"
            :placeholder="$t('AnalysisType')"
          />
        </div>
        <div
          class="vx-col md:w-1/2 lg:w-1/2 mb-4 w-full text-center"
          v-if="documentModel.DocumentID == 3"
        >
          <v-select
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
            label="Name"
            v-model="documentModel.DocumentTypeID"
            :options="ReportTypes"
            :reduce="(ID) => ID.ID"
            :placeholder="$t('ReportType')"
          />
        </div>
         <div
                    class=" mb-4 w-full text-center mb-1"
                  >
                    <vs-input 
                    v-model="documentModel.Description"
                    class="w-full"
                    :placeholder="$t('Description')" />
                  </div>
        <div
          class="vx-col md:w-2/3 sm:w-2/3 text-center"
          v-if=" 
                            documentModel.DocumentID > 0 &&
                            documentModel.DocumentTypeID > 0&&
                            documentModel.Description
                          "
        >
          <div>
            
            <input
            :disabled="
                          !(
                            documentModel.DocumentID > 0 &&
                            documentModel.DocumentTypeID > 0&&
                            documentModel.Description
                          )
                        "
              type="file"
              accept="application/pdf" 
              class=""
              @change="AttachmentUploaded"
              @on-success="successUpload"
            />
          </div>
        </div>

       
      </div>

      <div class="vx-row sm:w-full md:w-full">
        <table style="width: 100%; background-color: white" class="mt-5">
          <tr class="HeaderTableColor fontheader" style="height: 50px">
            <th class="text-center">
              {{ $t("DocumentName") }}
            </th>
            <th class="text-center">
              {{ $t("DocumentType") }}
            </th>
            <th class="text-center"></th>
          </tr>
          <tr
            :data="tr"
            :key="indextr"
            v-for="(tr, indextr) in ReportDocuments"
            style="height: 20px"
          >
            <td class="text-center">
              <p class="product-name font-medium truncate">
                {{ tr.Attach }}
              </p>
            </td>
            <td class="text-center">
              <p class="product-name font-medium truncate">
                {{ tr.Name }}-{{tr.DocumentID}}
              </p>
            </td>
            <td style="width: 10px">
              <div class="">
                 <feather-icon
                             icon="TrashIcon"
                              @click.stop="RemoveDocuments(indextr)"
                              class="cursor-pointer mt-2"
                            ></feather-icon>
              
              </div>
            </td>
          </tr>
          <tr style="height: 50px"></tr>
        </table>
      </div>

        <div class="vx-row">
      <div class="vx-col md:w-1/2 w-full " style="text-align: end;">
        <vs-button
          type="line"
          color="black"
          @click.stop="ReportDocuments=[];$emit('closePop')"
          
          >{{ $t("Back") }}</vs-button
        >
      </div>
        <div  v-if="ReportDocuments!=null&&ReportDocuments.length>0" class="vx-col md:w-1/2 w-full">
            <vs-button
              color="primary"
              @click="AddAttachmnet"
              >{{ $t("Save") }}</vs-button>
          </div>
      </div>
    </div>
  </div>
</template>
<script>
import modulePatient from "@/store/Patient/modulePatient.js";
import { domain, mainSetting } from "@/gloabelConstant.js";
import axios from "@/axios.js";
import moduleDocumentType from "@/store/settings/documentType/moduleDocumentType.js";
import moduleAnalysisType from "@/store/settings/analysisType/moduleAnalysisType.js";
import moduleReportType from "@/store/settings/reportType/moduleReportType.js";
import moduleScanType from "@/store/settings/scanType/moduleScanType.js";

export default {
  data() {
    return {
      UploadRequestHeader: mainSetting.UploadRequestHeader,
      uploadUrl: domain + "api/Common/UploadFile",
      baseURL: domain,
      // Model: [],
      uploaded: false,
      ReportData: {},
      ReportDocuments: [],
      ShowDocumentUpload: false,
      // documentModel:{},
    };
  },
  props: {
    documentModel: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    DocumentTypes() {
      return this.$store.state.DocumentTypeList.DocumentTypes;
    },
    ScanTypes() {
      return this.$store.state.ScanTypeList.ScanTypes;
    },
    ReportTypes() {
      return this.$store.state.ReportTypeList.ReportTypes;
    },
    AnalysisTypes() {
      return this.$store.state.AnalysisTypeList.AnalysisTypes;
    },
  },
  methods: {
    RemoveDocuments(index) {
      this.ReportDocuments.splice(index, 1);
    },
    AddAttachmnet() {
      debugger;
      this.ReportDocuments.forEach(element => {
        element.PatientID = this.$store.state.AppActiveUser.Patient.ID;
this.$store
        .dispatch("patientList/AddPatientMedicalDocument", element)
        .then((res) => {
          if (res.status == 200) {
             debugger;
            if (element.IsFromSession != true) {
              // this.getModel();
              this.$emit("openMedicalDocument");
            }
             debugger;
            //document.getElementsByClassName("btn-x-file")[0].click();
            this.uploaded = false;
             debugger;
          }
        })
        .catch(() => {
           debugger;
          window.showError();
        });
         debugger;
         this.ReportDocuments=[];
           window.showAddSuccess();

      });
      
    },
    successUpload(event) {
      debugger;

      window.showAddSuccess();
    },
    AttachmentUploaded(input) {
    
      if (input.target.files && input.target.files[0]) {
        let data = new FormData();
        data.append("file", input.target.files[0]);

        axios.post(this.uploadUrl, data,{
          headers: {
              "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
            debugger;
        
          if (this.documentModel.DocumentID == 1)
              this.documentModel.Name = "Scan Type";
          if (this.documentModel.DocumentID == 2)
              this.documentModel.Name = "Analysis Type";
           if (this.documentModel.DocumentID == 3) 
              this.documentModel.Name = "Report Type";

          this.documentModel.Attach = input.target.files[0].name;
          this.documentModel.AttachPath = response.data;
          this.ReportDocuments.push(this.documentModel);
          this.documentModel = {};
        });
      }
    },
  },
  created() {
    if (!moduleScanType.isRegistered) {
      this.$store.registerModule("ScanTypeList", moduleScanType);
      moduleScanType.isRegistered = true;
    }

    this.$store.dispatch("ScanTypeList/GetAllScanTypes");
    if (!moduleReportType.isRegistered) {
      this.$store.registerModule("ReportTypeList", moduleReportType);
      moduleReportType.isRegistered = true;
    }

    this.$store.dispatch("ReportTypeList/GetAllReportTypes");
    if (!moduleAnalysisType.isRegistered) {
      this.$store.registerModule("AnalysisTypeList", moduleAnalysisType);
      moduleAnalysisType.isRegistered = true;
    }

    this.$store.dispatch("AnalysisTypeList/GetAllAnalysisTypes");
    if (!moduleDocumentType.isRegistered) {
      this.$store.registerModule("DocumentTypeList", moduleDocumentType);
      moduleDocumentType.isRegistered = true;
    }

    this.$store.dispatch("DocumentTypeList/GetAllDocumentTypes");
    if (!modulePatient.isRegistered) {
      this.$store.registerModule("patientList", modulePatient);
      modulePatient.isRegistered = true;
    }

    // var Obj = {
    //   patientID: this.$store.state.AppActiveUser.Patient.ID
    // };
    // this.getModel();
  },
};
</script>
<style>
#UPMReport table {
  border-collapse: collapse;
  border-radius: 2em;
  overflow: hidden;
}
#UPMReport th,
#UPMReport td {
  border: 1px solid rgb(255, 255, 255);
  border-collapse: collapse;
}
#UPMReport .mycard .vx-card {
  background: unset;
}
#UPMReport .login-tabs-container {
  min-height: 505px;
}
#UPMReport .con-tab {
  padding-bottom: 14px;
}

#UPMReport .con-slot-tabs {
  margin-top: 1rem;
}
#UPMReport .background {
  color: rgb(255, 9, 9);
}
#UPMReport .stack-top {
  z-index: 9;
  border-radius: 5px;
  background: #202020;
}
.head {
  color: white;
  text-align: center;
  margin-top: 20px;
}
[dir] #UPMReport th,
[dir] #UPMReport td {
  border: 1px solid #cfcfcf !important;
}
#UPMReport .con-img-upload {
  width: 100%;
  background: #fff;
  margin-top: -2rem;
  text-align: center !important;
  height: 6rem;
}
#UPMReport .con-input-upload {
  background: #f5f5f5;

  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 8px;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  border: 1px dashed rgba(0, 0, 0, 0.1);
  display: -webkit-box;
  display: -ms-flexbox;
  display: inline-block;
  margin-left: -1.5rem;
}

#UPMReport .vs-upload--button-upload {
  position: relative;
  border: 0;

  font-size: 0.8rem;

  cursor: pointer;
  margin: 0;
  color: rgba(var(--vs-primary), 1);
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  -webkit-box-shadow: 0 0 0 0 rgb(0 0 0 / 5%);
  box-shadow: 0 0 0 0 rgb(0 0 0 / 5%);
  background: rgba(0, 0, 0, 0.03);
  bottom: 0;
  width: 100%;
}
</style>
