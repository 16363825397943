<template>
  <div class="flex items-center justify-center inset-0 ...">
    <div class="flex w-full bg-img fontMedium">
      <div class="mx-auto self-center" :style="[ windowWidth<=500? {'width':'98%'}: {'width':'70%'}]">
        <div
          class="absolute justify-center mt-5"
          style="max-width: 130px; margin-left: 31%; margin-right: 31%"
          slot="no-body"
        >
           <popLogo />
        </div>
        <div slot="no-body" class="full-page-bg-color CustBGPay">
          <div class="vx-row">
            <div
              class="
                vx-col
                hidden
                sm:hidden
                md:hidden
                lg:block lg:w-1/2
                mx-auto
              "
            >
              <p
                class="pl-10 pr-10"
                style="
                  text-align: center;
                  font: normal normal 50 25px/41px UbuntuRegular;
                  letter-spacing: 0px;
                  color: #ffffff;
                  opacity: 1;
                "
              >
                {{ $t("ProccedOnlineAppointment") }} <br />
                {{ $t("withDoctor") + " " + sessionModel.DoctorName }}
              </p>
              <div class="pl-10 pr-10">
                <div class="vx-row">
                  <div class="vx-col pt-2">
                    <img
                      src="@/assets/images/logo/appointment.png"
                      style="max-width: 50px; opacity: 0.3"
                      alt=""
                    />
                  </div>
                  <div class="vx-col">
                    <p class="OtherPageStatment pt-3">
                      {{ $t("STEPONE") }}<br />{{ $t("OneSetAppointment") }}
                    </p>
                  </div>
                </div>

                <hr class="line" style="opacity: 0.3" />

                <div class="vx-row">
                  <div class="vx-col pt-3">
                    <img
                      src="@/assets/images/logo/document.png"
                      style="max-width: 40px; opacity: 0.3"
                      alt=""
                    />
                  </div>
                  <div class="vx-col">
                    <p class=" OtherPageStatment pt-4">
                      {{ $t("STEPTWO") }}<br />{{ $t("TwoSetAppointment") }}
                    </p>
                  </div>
                </div>
                <hr class="line" />

                <div class="vx-row">

                  <div class="vx-col pt-3">
                    <img
                      src="@/assets/images/logo/payment-method.png"
                      style="max-width: 50px"
                      alt=""
                    />
                  </div>
                  <div class="vx-col">
                    <p class="CurrentPageStatment pt-4">
                      {{ $t("STEPTREE") }}<br />{{ $t("ThreeSetAppointment") }}
                    </p>
                  </div>
                </div>
                <span style="color:white;font-size:15px;text-align:center">{{$t('AcceptPaymentText')}}  </span>
 <div class="vx-row pt-3 pb-3" style="text-align:center;justify-content: center;">
 <img
                      src="@/assets/images/Group 2.png"
                      style="max-width: 60px;"
                      alt=""
                      class="ml-3"
                    />

                    <img
                      src="@/assets/images/Group 1.png"
                      style="max-width: 60px;"
                      alt=""
                       class="ml-3"
                    />
                      <img
                      src="@/assets/images/Group 3.png"
                      style="max-width: 60px;"
                      alt=""
                       class="ml-3"
                    />
                      <img
                      src="@/assets/images/Group 4.png"
                      style="max-width: 60px;"
                      alt=""
                       class="ml-3"
                    />
 </div>
              </div>
            </div>

            <div
              class="
                vx-col
                sm:w-full
                md:w-full
                lg:w-1/2
                mx-auto
                d-theme-dark-bg
                lg:block
              "
              style="border-radius: 31px 31px 31px 31px"
            >
              <div class="p-8" style="margin-top: 50px">
                <div class="vx-row text-center">
                  <p
                    class="
                      vx-col
                      sm:w-full
                      md:w-full
                      mx-auto
                      self-center
                      d-theme-dark-bg
                      col
                      pl-10
                      pr-10
                    "
                    style="
                      text-align: center;
                      font: normal normal 50 20px/30px UbuntuRegular;
                      letter-spacing: 0px;
                      opacity: 1;
                    "
                  >
                    {{ $t("PaymentInformation") }}
                  </p>
                </div>


                <!--
                  this to show if session date not equle date time now when user boook
                   <span>{{changeFormatedDate(sessionModel.SessionDate)}}</span>
                  <br>
                  <span>{{changeFormatedDate(appointmentDate)}}</span>
                  <br>
                  <span>{{changeFormatedDate(sessionModel.SessionDate) != changeFormatedDate(appointmentDate)}}</span> -->

                <div class="vx-card__title mb-2"></div>
                <div
                  class="
                  mt-5
                    vx-col
                    sm:w-full
                    md:w-full
                    mx-auto
                    self-center
                    d-theme-dark-bg
                  "
                  style="min-height: 180px"
                >

                  <!-- <div class="vx-row text-center">
                    <label class="col lg:w-1/4 w-full mt-3" for="fname"
                      >{{ $t("CardHolder") }}:</label
                    >
                    <vs-input
                      name="fname"
                      class="col lg:w-2/3 w-full mb-2"
                      placeholder=""
                      v-model="sessionModel.CardHolder"
                    />
                  </div>
                  <div class="vx-row text-center">
                    <label class="col lg:w-1/4 w-full mt-3" for="fname"
                      >{{ $t("CardNumber") }}:</label
                    >
                    <vs-input
                      name="fname"
                      class="col lg:w-2/3 w-full mb-2"
                      placeholder=""
                      v-model="sessionModel.CardNumber"
                    />
                  </div>
                  <div class="vx-row text-center">
                    <label class="col lg:w-1/4 w-full mt-3" for="fname"
                      >{{ $t("ExpiryDate") }}:</label
                    >
                    <vs-input
                      name="fname"
                      class="col lg:w-2/3 w-full mb-2"
                      placeholder=""
                      v-model="sessionModel.ExpiryDate"
                    />
                  </div>
                  <div class="vx-row text-center">
                    <label class="col lg:w-1/4 w-full mt-3" for="fname"
                      >{{ $t("CVV") }}:</label
                    >
                    <vs-input
                      name="fname"
                      class="col lg:w-2/3 w-full mb-6"
                      placeholder=""
                      v-model="sessionModel.CVV"
                    />
                  </div> -->

                    <p >{{$t('payOrPaylaterNote1')}}</p>
                    <p >{{$t('payOrPaylaterNote2')}}</p>
                    <p >{{$t('payOrPaylaterNote3')}}  </p>
                     <p></p>
                </div>

                <div
                  class="
                    flex flex-wrap
                    justify-center
                    flex-col-reverse
                    sm:w-full
                  "
                >
                  <vs-button
                    class="w-full sm:w-auto text-center"
                    @click="PayNow"
                    >{{ $t("Confirm") }}</vs-button
                  >
                </div>
                <div
                  class="
                    flex flex-wrap
                    justify-center
                    flex-col-reverse
                    sm:w-full
                  "
                >
                  <div class="text-center" v-if="sessionModel.Paylater && (changeFormatedDate(sessionModel.SessionDate) != changeFormatedDate(appointmentDate))">
                    <vs-button
                      type="line"
                      @click="canclePayment()"
                      class="w-full sm:w-auto mb-8 sm:mb-auto mt-3 sm:mt-auto"
                      >{{ $t("PayLater") }}</vs-button
                    >
                  </div>
                   <div class="text-center" v-if="sessionModel.Paylater && (changeFormatedDate(sessionModel.SessionDate) != changeFormatedDate(appointmentDate))">
                    <vs-button
                      type="line"
                      @click="canclePayment()"
                      class="w-full sm:w-auto mb-8 sm:mb-auto mt-3 sm:mt-auto"
                      >{{ $t("Close") }}</vs-button
                    >
                  </div>
                    <p v-else style="color:red">{{$t('CantPaylater')}}</p>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";

export default {
  data() {
    return {
      isSelected: false,
      appointmentDate: new Date().toDateString(),
      appointmentDateStrig: "",
      sessions: [],
      updatedPatientSession: {},

    };
  },
  props: {
    sessionModel: {
      type: Object,
      default: () => {},
    },
  updatedPatientSession: {
      type: Object,
      default: () => {},
    },
  },
  computed:{
   windowWidth() {
      return this.$store.state.windowWidth;
    },
  },

  methods: {

    changeFormatedDate(date) {
      return moment(date).format("LL");
    },

    compareDate(){
      debugger;
      if(changeFormatedDate(appointmentDate) > changeFormatedDate(sessionModel.SessionDate)){
        return true;
      }
      return false;
    },
  PayNow() {
    this.updatedPatientSession.FrontUrl = window.location.origin
      this.$store
        .dispatch("patientList/AppointmentReservationPaymentDatail", this.updatedPatientSession)
        .then((res) => {
          debugger;
          window.location.href  = res.data.Data.transaction.url;
          // document.getElementById("amount").value = res.data.Data.amount;
          // document.getElementById("customer_email").value =
          //   res.data.Data.customer_email;
          // document.getElementById("signature").value = res.data.Data.signature;
          // document.getElementById("merchant_reference").value =
          //   res.data.Data.merchant_reference;
          // document.getElementById("paymentForm").submit();

          //   var vm=this;
          //    setTimeout(function(){
          //      vm.$emit("closePayNow");

          //    },1000)

        });

    },

    canclePayment() {
      this.$emit("closePop",false);
      //this.$router.push("/Patient/Home");
    },
    Pay() {
      this.$emit("closePop", true);
    },
  },
  mounted() {},
};
</script>
<style>
.doctorImage {
  width: 100px;
  height: 100px;
  text-align: end;
}
.CurrentPageStatment {
  top: 414px;
  left: 600px;
  height: 100%;
  width: 100%;
  color: var(--unnamed-color-ffffff);
  text-align: left;
  letter-spacing: NaNpx;
  color: #ffffff;
  opacity: 1;
  font-family: UbuntuRegular;
  font-size: 1.3rem;
}
.OtherPageStatment {
  top: 532px;
  left: 600px;
  height: 100%;
  width: 100%;
  color: var(--unnamed-color-ffffff);
  text-align: left;
  letter-spacing: NaNpx;
  color: #ffffff;
  opacity: 0.3;
  font-family: UbuntuRegular;
  font-size: 1.3rem;
}
.text-1xl {
  color: #393939;

  font-size: 1rem;
  letter-spacing: 0px;

  opacity: 0.5;
}
.CustBGPay {
  background: transparent radial-gradient(closest-side at 50% 50%, #2f5f80 0%, #003267 100%) 0% 0% no-repeat padding-box;
  box-shadow: -3px 0px 22px #00000029;
  border-radius: 31px 31px 31px 31px;
  opacity: 1;
}
</style>
